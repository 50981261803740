import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import CKDocs from '@workday/canvas-kit-docs/dist/mdx/react/select/Select.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const PackageInfo = makeShortcode("PackageInfo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabPanel data-id="Web" mdxType="TabPanel">
      <PackageInfo github="/modules/react/select" storybook="https://workday.github.io/canvas-kit/?path=/story/components-inputs-select--basic" figma="https://www.figma.com/design/6Y3uHyGKnS8m0DzWBLeL73/Canvas-Web-v11?node-id=3984-935&t=UhTeJ6F4tDSMVJmL-4" package="@workday/canvas-kit-react" mdxType="PackageInfo" />
      <h2 {...{
        "id": "anatomy"
      }}>{`Anatomy`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/c27cb8a5c97381b0fd4f4d27c157c12e/536c7/component-anatomy-select.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "20%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Image of a Select Input in its default state.",
              "title": "Image of a Select Input in its default state.",
              "src": "/static/c27cb8a5c97381b0fd4f4d27c157c12e/50383/component-anatomy-select.png",
              "srcSet": ["/static/c27cb8a5c97381b0fd4f4d27c157c12e/1efb2/component-anatomy-select.png 370w", "/static/c27cb8a5c97381b0fd4f4d27c157c12e/50383/component-anatomy-select.png 740w", "/static/c27cb8a5c97381b0fd4f4d27c157c12e/536c7/component-anatomy-select.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ol>
        <li parentName="ol"><strong parentName="li">{`Label:`}</strong>{` Title of the input.`}</li>
        <li parentName="ol"><strong parentName="li">{`Input Container:`}</strong>{` Rectangular container that houses the icon and placeholder text.`}</li>
        <li parentName="ol"><strong parentName="li">{`Placeholder Text (Optional):`}</strong>{` Placeholder text like “Select One” is typically displayed in the
Select field. After the user makes a selection, the placeholder text is replaced with the user’s
selection.`}</li>
        <li parentName="ol"><strong parentName="li">{`Icon:`}</strong>{` Caret icon positioned to the right of the container visually distinguishes this as a
Select input.`}</li>
      </ol>
      <h2 {...{
        "id": "usage-guidance"
      }}>{`Usage Guidance`}</h2>
      <ul>
        <li parentName="ul">{`Clicking or tapping anywhere in a Select opens the Menu.`}</li>
        <li parentName="ul">{`A checkmark icon indicates which value is currently selected in the list.`}</li>
        <li parentName="ul">{`Each Menu option should be distinct. If the option isn’t discrete, combine it with another option.`}</li>
        <li parentName="ul">{`The list of Menu options should be sorted in a logical order alphabetically, chronologically, or
by order of importance.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use"
      }}>{`When to Use`}</h3>
      <ul>
        <li parentName="ul">{`Use Select as a form element where users are only allowed to select one item from a list of more
than 7 predefined options.`}</li>
        <li parentName="ul">{`Typically, Selects work best when the list is between 7 to 15 items to prevent overwhelming the
user with too many options.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use-something-else"
      }}>{`When to Use Something Else`}</h3>
      <ul>
        <li parentName="ul">{`Consider using a `}<a parentName="li" {...{
            "href": "/components/inputs/switch"
          }}>{`Switch`}</a>{` if the only options are yes or no.`}</li>
        <li parentName="ul">{`For a list between 2 to 7 predefined options, consider using
`}<a parentName="li" {...{
            "href": "/components/inputs/radio"
          }}>{`Radio Buttons`}</a>{` to select one option or
`}<a parentName="li" {...{
            "href": "/components/inputs/checkbox"
          }}>{`Checkboxes`}</a>{` to select multiple options. Radio and Checkbox groups
display all options upfront and do not require the user to interact with the input to view the
list of options.`}</li>
        <li parentName="ul">{`Use a Prompt when the number of list items is large or unknown. Prompts have search capabilities
and folders which provide users with the means to browse options. Prompts can be configured to
support single or multi-select.`}</li>
      </ul>
      <CKDocs mdxType="CKDocs" />
      <h2 {...{
        "id": "accessibility-guidelines"
      }}>{`Accessibility Guidelines`}</h2>
      <h3 {...{
        "id": "keyboard-interaction"
      }}>{`Keyboard Interaction`}</h3>
      <p>{`Each Select must have a focus indicator that is highly visible against the background and against
the non-focused state. Refer to `}<a parentName="p" {...{
          "href": "/guidelines/accessibility/accessible-color"
        }}>{`Accessible Colors`}</a>{` for more
information.`}</p>
      <p>{`Select must support the following keyboard interactions:`}</p>
      <ul>
        <li parentName="ul"><inlineCode parentName="li">{`Tab`}</inlineCode>{`: focus the Select component`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`Enter`}</inlineCode>{` or `}<inlineCode parentName="li">{`Space`}</inlineCode>{`: open the Select list box and focus the first option`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`Esc`}</inlineCode>{`: dismiss the Select list box and focus the Select component`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`Up Arrow`}</inlineCode>{` or `}<inlineCode parentName="li">{`Down Arrow`}</inlineCode>{`: focus the previous or next option respectively`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`Character Key`}</inlineCode>{`: focus options matching character key`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`Home`}</inlineCode>{` or `}<inlineCode parentName="li">{`Fn + Up Arrow`}</inlineCode>{`: focus first option`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`End`}</inlineCode>{` or `}<inlineCode parentName="li">{`Fn + Down Arrow`}</inlineCode>{`: focus last option`}</li>
      </ul>
      <h3 {...{
        "id": "screen-reader-interaction"
      }}>{`Screen Reader Interaction`}</h3>
      <p>{`Select must communicate the following to users:`}</p>
      <ul>
        <li parentName="ul">{`This component is a “combo” or “combobox”`}</li>
        <li parentName="ul">{`The associated label`}</li>
        <li parentName="ul">{`The currently selected value`}</li>
        <li parentName="ul">{`The “collapsed” or “expanded” state`}</li>
        <li parentName="ul">{`When opened, there is a list of ‘X’ items`}</li>
        <li parentName="ul">{`When opened, the name of the active option`}</li>
        <li parentName="ul">{`When opened, the position “X of Y” for the active option`}</li>
      </ul>
      <h3 {...{
        "id": "design-annotations-needed"
      }}>{`Design Annotations Needed`}</h3>
      <p>{`No design annotations required for Select.`}</p>
      <h3 {...{
        "id": "implementation-markup-needed"
      }}>{`Implementation Markup Needed`}</h3>
      <p>{`The Select component is built around the
`}<a parentName="p" {...{
          "href": "https://www.w3.org/WAI/ARIA/apg/patterns/combobox/examples/combobox-select-only/"
        }}>{`ARIA Combobox v1.2 specification for Select-Only`}</a>{`.
`}<strong parentName="p">{`Support for this component may be limited by browser vendors and/or screen readers.`}</strong>{` Also, note
any reference to “menu” on this page does not refer to an ARIA `}<inlineCode parentName="p">{`menu`}</inlineCode>{` pattern. The Select component
is using an ARIA `}<inlineCode parentName="p">{`listbox`}</inlineCode>{` pattern for the options.`}</p>
      <ul>
        <li parentName="ul">{`A `}<inlineCode parentName="li">{`<label>`}</inlineCode>{` element must be used with a `}<inlineCode parentName="li">{`for`}</inlineCode>{` attribute referencing the unique `}<inlineCode parentName="li">{`id`}</inlineCode>{` value of the
Select input.`}</li>
        <li parentName="ul">{`Select input must have a `}<inlineCode parentName="li">{`required`}</inlineCode>{` attribute when the field is required for form submission.`}</li>
        <li parentName="ul">{`Select input must have an `}<inlineCode parentName="li">{`aria-describedby`}</inlineCode>{` attribute referencing the unique `}<inlineCode parentName="li">{`id`}</inlineCode>{` value of the
inline hint text below the field.`}</li>
        <li parentName="ul">{`Select input must have an `}<inlineCode parentName="li">{`aria-invalid=”true”`}</inlineCode>{` attribute when the field has an error condition.`}</li>
        <li parentName="ul">{`Select input must have a `}<inlineCode parentName="li">{`disabled`}</inlineCode>{` attribute when the field is disabled.`}</li>
      </ul>
      <h2 {...{
        "id": "content-guidelines"
      }}>{`Content Guidelines`}</h2>
      <ul>
        <li parentName="ul">{`The list of Menu items should be scannable, with concise labels written in title case. Don’t write
sentences and omit articles (a, an, the) unless needed for clarity. For more detailed information
on how to write Menu items, refer to the `}<a parentName="li" {...{
            "href": "/guidelines/content/ui-text/dropdown-menus"
          }}>{`Drop-down Menus`}</a>{` section
of the Content Style Guide.`}</li>
        <li parentName="ul">{`Placeholder text for a Select must begin with the verb “Select”. Refer to the guidelines on
`}<a parentName="li" {...{
            "href": "/guidelines/content/ui-text/placeholder-text"
          }}>{`Placeholder Text`}</a>{` in the Content Style Guide for more tips on
how to write placeholder text.`}</li>
      </ul>
    </TabPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      